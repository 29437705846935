import { Button } from '@chakra-ui/button';
import { Input, Spacer } from '@chakra-ui/react';
import { DocumentSnapshot } from '@firebase/firestore';
import { Campaign } from '../../types/Campaign';
import { Section } from '../Section';

interface Props {
  campaignSnap?: DocumentSnapshot<Campaign>;
  onUploadClick: () => void;
}

export const TopBar: React.FC<Props> = ({ onUploadClick }) => {
  return (
    <Section
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      variant="bottom"
      minHeight="6vh"
      px={6}
      py={4}
    >
      <Input
        size="sm"
        maxWidth="300px"
        variant="filled"
        type="text"
        placeholder="Search"
        borderRadius="md"
      />
      <Spacer />
      <Button size="sm" colorScheme="purple" onClick={onUploadClick}>
        Upload
      </Button>
    </Section>
  );
};
