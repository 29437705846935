import {
  doc,
  DocumentData,
  DocumentSnapshot,
  query,
  QuerySnapshot,
  where,
  collection,
  orderBy,
} from '@firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { firestore } from '../config/firebase';
import { Campaign } from '../types/Campaign';
import { useAuth } from './useAuth';

type UseCreativeByCampaign = [
  QuerySnapshot<DocumentData> | undefined,
  boolean,
  Error | undefined,
];

interface Props {
  campaignSnap?: DocumentSnapshot<Campaign>;
}

export function useCreativeByCampaign({
  campaignSnap,
}: Props): UseCreativeByCampaign {
  const { userRef } = useAuth();
  const campaignRef = campaignSnap?.id
    ? doc(firestore, `campaigns/${campaignSnap?.id}`)
    : null;

  const creativeRef = query(
    collection(firestore, 'creative'),
    where('campaignRef', '==', campaignRef),
    where('userRef', '==', userRef),
    orderBy('createdAt', 'desc'),
  );

  const [creativeSnaps, isLoading, error] = useCollection(creativeRef);

  if (error) {
    console.log('useCreativeByCampaign.error');
    console.error(error);
  }

  return [creativeSnaps, isLoading, error];
}
