export const Card = {
  // The styles all Cards have in common
  baseStyle: ({ colorMode }: any): any => ({
    display: 'flex',
    flexDirection: 'column',
    bgColor: colorMode === 'light' ? 'white' : 'gray.800',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 6,
  }),
  // Two variants: rounded and smooth
  variants: {
    rounded: {
      padding: 8,
      borderRadius: 'xl',
      boxShadow: 'xl',
    },
  },
  // The default variant value
  defaultProps: {
    variant: 'rounded',
  },
};
