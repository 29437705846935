import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Gradient } from '../Gradient';

interface IPageProps {
  title?: string;
  withGradient?: boolean;
  withHeader?: boolean;
  children: React.ReactElement;
}

const HelmetTemp = Helmet as any;

export const Page: React.FC<IPageProps> = ({
  title,
  withGradient = false,
  children,
}) => (
  <div style={{ height: '100vh', width: '100vw' }}>
    <HelmetTemp>
      <title>{title ? `${title} • ` : ''}Creato</title>
    </HelmetTemp>
    {withGradient ? (
      <Gradient height="100vh" width="100vw">
        {children}
      </Gradient>
    ) : (
      children
    )}
  </div>
);
