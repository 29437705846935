import { useEffect, useState } from 'react';
import { getDownloadURL, ref } from '@firebase/storage';

import { storage } from '../config/firebase';

export function useMedia(fileRef: string): string | undefined {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const init = async () => {
      if (fileRef) {
        const media = ref(storage, fileRef);
        const nextUrl = await getDownloadURL(media);
        setUrl(nextUrl);
      }
    };

    init();
  }, [fileRef]);

  return url;
}
