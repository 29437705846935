import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { DocumentSnapshot } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { relativeDate } from '../../lib/dateFormatter';
import { Comment as CommentType } from '../../types/Comment';

interface Props {
  commentSnap?: DocumentSnapshot<CommentType>;
}

export const Comment = ({ commentSnap }: Props): React.ReactElement => {
  const comment = commentSnap?.data();
  const [userSnap] = useDocument(comment?.userRef);
  const user = userSnap?.data();

  return (
    <Box width="full">
      <Stack direction="row">
        <Image
          borderRadius="full"
          boxSize="50px"
          mr={2}
          src={user?.avatarUri}
        />
        <Stack flex={1} spacing={0}>
          <HStack justifyContent="space-between">
            <Text fontSize="sm" opacity="0.4">
              {user?.name}
            </Text>
            <Text fontSize="sm" opacity="0.4">
              {relativeDate(comment?.createdAt?.toDate())}
            </Text>
          </HStack>
          <Text>{comment?.content}</Text>
        </Stack>
      </Stack>
    </Box>
  );
};
