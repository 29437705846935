import * as React from 'react';
import { useFormikContext } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

export const SimpleField: React.FC<any> = ({
  name,
  label,
  children,
  helper,
  ...rest
}) => {
  const { getFieldProps, touched, errors } = useFormikContext();
  const error = name.split('.').reduce((a: any, b: any) => a?.[b], errors);
  const isTouched = name.split('.').reduce((a: any, b: any) => a?.[b], touched);
  const isInvalid = !!isTouched && !!error;

  return (
    <FormControl id={name} name={name} isInvalid={isInvalid} {...rest}>
      <FormLabel>{label}</FormLabel>
      {typeof children === 'function'
        ? children({ fieldProps: getFieldProps(name) })
        : children}
      <FormErrorMessage>{error}</FormErrorMessage>
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
};
