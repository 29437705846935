import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { HiExclamation, HiThumbDown, HiThumbUp } from 'react-icons/hi';

interface Props {
  status?: string;
}

export const StatusTag = ({ status }: Props): React.ReactElement => {
  const isApproved = status?.toLowerCase() === 'approved';
  const isRejected = status?.toLowerCase() === 'rejected';

  if (isApproved) {
    return (
      <Tag size="md" colorScheme="green">
        <TagLeftIcon boxSize="16px" as={HiThumbUp} />
        <TagLabel>Approved</TagLabel>
      </Tag>
    );
  }

  if (isRejected) {
    return (
      <Tag size="md" colorScheme="red">
        <TagLeftIcon boxSize="16px" as={HiThumbDown} />
        <TagLabel>Rejected</TagLabel>
      </Tag>
    );
  }

  return (
    <Tag size="md" colorScheme="orange">
      <TagLeftIcon boxSize="16px" as={HiExclamation} />
      <TagLabel>Pending</TagLabel>
    </Tag>
  );
};
