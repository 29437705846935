import { useToast } from '@chakra-ui/toast';

interface UseErrors {
  handleError: (error: Error | any) => void;
}

export function useErrors(): UseErrors {
  const toast = useToast({
    title: 'Uhoh!',
    status: 'error',
    position: 'bottom',
  });

  const handleError = (error: Error | any) => {
    const { message: description } = error;

    console.error(description);
    toast({ description });
  };

  return {
    handleError,
  };
}
