import {
  sendEmailVerification,
  signInWithEmailAndPassword,
  User,
} from '@firebase/auth';
import {
  doc,
  DocumentData,
  DocumentReference,
  setDoc,
} from '@firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { firestore } from '../config/firebase';
import { useErrors } from './useErrors';

interface UseAuth {
  user?: User | null;
  userRef?: DocumentReference<DocumentData> | null;
  isAdmin?: boolean;
  isAuthenticated?: boolean;
  isLoading: boolean;

  // Methods
  register: (email: string, password: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

async function resolveIsAdmin(user: User | null) {
  if (!user) {
    return false;
  }

  const { claims } = await user.getIdTokenResult();
  return !!claims.isAdmin;
}

export function useAuth(): UseAuth {
  const auth = getAuth();
  const { handleError } = useErrors();

  const [user, setUser] = useState<User | undefined | null>(auth?.currentUser);
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const cleanup = auth.onAuthStateChanged(async (nextUser) => {
      setIsLoading(true);

      try {
        setUser(nextUser);

        setIsAdmin(await resolveIsAdmin(nextUser));
      } catch (reason) {
        handleError(reason);
      } finally {
        setIsLoading(false);
      }
    });

    () => cleanup();
  }, [auth]);

  const register = async (email: string, password: string) => {
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);

      // Send Email Verification
      if (!user) new Error('Missing User');
      await sendEmailVerification(user?.user);

      // Create firestore doc
      const userRefToCreate = doc(firestore, `users/${user?.user?.uid}`);
      await setDoc(userRefToCreate, {});
    } catch (reason) {
      handleError(reason);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (reason) {
      handleError(reason);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      location.reload();
    } catch (reason) {
      handleError(reason);
    }
  };

  const userRef = user?.uid ? doc(firestore, `users/${user?.uid}`) : null;

  return {
    user,
    userRef,
    isAdmin,
    isAuthenticated: !!user,
    isLoading,

    // Methods
    register,
    login,
    logout,
  };
}
