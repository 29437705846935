interface Config {
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
}

function getConfig(): Config {
  if (!process.env.REACT_APP_FIREBASE_PROJECT) {
    throw new Error(
      'REACT_APP_FIREBASE_PROJECT required to initialize application',
    );
  }

  try {
    // eslint-disable-next-line
    return require(`./${process.env.REACT_APP_FIREBASE_PROJECT}.json`);
  } catch (e: any) {
    throw new Error(`Failed to load firebase config: ${e.message}`);
  }
}

export const config = getConfig();
