import {
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { DocumentSnapshot } from '@firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Link as RRLink, useLocation } from 'react-router-dom';
import { useComments } from '../../hooks/useComments';

import { useMedia } from '../../hooks/useMedia';
import { relativeDate } from '../../lib/dateFormatter';
import { Campaign } from '../../types/Campaign';
import { Creative } from '../../types/Creative';
import { Card } from '../Card';
import { CommentCountTag } from '../CommentCountTag';
import { StatusTag } from '../StatusTag';
import { ViewsCountTag } from '../ViewsCountTag';

interface Props {
  campaignSnap?: DocumentSnapshot<Campaign>;
  creativeSnap: DocumentSnapshot<Creative>;
}

export const CreativeItem: React.FC<Props> = ({
  campaignSnap,
  creativeSnap,
}) => {
  const creative = creativeSnap?.data() as Creative;
  const uri = useMedia(creative?.versions?.[0]?.fileRef);

  const [authorSnap] = useDocument(creative?.userRef);
  const author = authorSnap?.data();

  const [comments] = useComments(creativeSnap);

  const { pathname } = useLocation();
  const id = campaignSnap?.id;
  const toLink = `/campaigns/${id}/creative/${creativeSnap?.id}`;
  const isSelected = toLink === pathname;

  const color = useColorModeValue('gray.100', 'gray.700');
  const bgColor = isSelected ? color : 'transparent';

  return (
    <LinkBox m={4}>
      <Card
        p={0}
        bgColor={bgColor}
        borderWidth="1px"
        borderStyle="solid"
        borderColor="transparent"
        boxShadow="none"
        _hover={{ borderColor: color }}
      >
        <Stack direction="row" spacing={0} width="100%">
          <Image
            src={uri}
            width="115px"
            height="200px"
            borderRadius="lg"
            objectFit="cover"
            align="center"
          />
          <Stack
            flex={1}
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            p={4}
          >
            <LinkOverlay as={RRLink} to={toLink}>
              <Text fontSize="lg" fontWeight="medium">
                {creative.title}
              </Text>
            </LinkOverlay>
            <Text opacity="0.5">
              {author?.name} • {relativeDate(creative?.createdAt?.toDate())}
            </Text>
            <Spacer />
            <HStack w="full">
              <StatusTag status={creative?.status} />
              <Spacer />
              <CommentCountTag count={comments?.docs?.length} />
              <ViewsCountTag count={creative?.views} />
            </HStack>
          </Stack>
        </Stack>
      </Card>
    </LinkBox>
  );
};
