export const Section = {
  baseStyle: ({ colorMode }: any): any => ({
    bgColor: colorMode === 'light' ? 'white' : 'gray.800',
    borderColor: colorMode === 'light' ? 'gray.200' : 'gray.700',
    borderWidth: 0,
  }),
  variants: {
    all: { borderWidth: 1 },
    left: { borderLeftWidth: 1 },
    top: { borderTopWidth: 1 },
    right: { borderRightWidth: 1 },
    bottom: { borderBottomWidth: 1 },
  },
};
