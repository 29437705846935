import {
  addDoc,
  collection,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  serverTimestamp,
  updateDoc,
} from '@firebase/firestore';
import { ref, uploadBytes } from '@firebase/storage';
import { useEffect, useState } from 'react';

import { firestore, storage } from '../../config/firebase';
import { useAuth } from '../../hooks/useAuth';
import { useErrors } from '../../hooks/useErrors';
import { Campaign } from '../../types/Campaign';
import { Creative, Version } from '../../types/Creative';

interface Props {
  file: File;
  campaignSnap?: DocumentSnapshot<Campaign>;
}

export const CreativeItemUploader: React.FC<Props> = ({
  file,
  campaignSnap,
}) => {
  const [, setCreativeSnap] = useState<DocumentSnapshot<Creative>>();
  const [uploadStarted, setUploadStarted] = useState<boolean>(false);
  const { handleError } = useErrors();
  const { userRef } = useAuth();
  const campaignRef = campaignSnap?.ref;

  const storageRef = ref(storage, 'creative');

  useEffect(() => {
    (async () => {
      try {
        if (!userRef || uploadStarted) return;

        setUploadStarted(true);
        const creativesRef = collection(firestore, 'creative');
        const creativeRef = await addDoc(creativesRef, {
          createdAt: serverTimestamp(),
          title: file?.name,
          campaignRef,
          userRef,
        });
        const nextCreativeSnap = await getDoc<Creative>(
          creativeRef as DocumentReference<Creative>,
        );

        setCreativeSnap(nextCreativeSnap);
        const uploadRef = ref(storageRef, nextCreativeSnap?.id);
        await uploadBytes(uploadRef, file);

        const newVersion: Version = {
          createdAt: new Date().toUTCString(),
          fileRef: uploadRef.toString(),
        };
        await updateDoc(nextCreativeSnap?.ref, {
          updatedAt: serverTimestamp(),
          versions: [newVersion],
        });
      } catch (error) {
        handleError(error);
      }
    })();
  }, [userRef]);

  if (!campaignSnap) {
    handleError(new Error('Please define a campaign to upload.'));
  }

  return null;
};
