import { IconButton } from '@chakra-ui/button';
import { useColorMode } from '@chakra-ui/color-mode';
import { Icon } from '@chakra-ui/icon';
import { HStack } from '@chakra-ui/layout';
import { HiLogout, HiMoon, HiSun } from 'react-icons/hi';
import { useAuth } from '../../hooks/useAuth';

export const UtilityFunctions: React.FC = () => {
  const { logout } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  const isLight = colorMode === 'light';

  const colorModeIcon = isLight ? <Icon as={HiMoon} /> : <Icon as={HiSun} />;

  return (
    <HStack position="fixed" left="1vw" bottom="1vw">
      <IconButton
        icon={colorModeIcon}
        aria-label="Toggle Color Mode"
        onClick={toggleColorMode}
      />
      <IconButton
        icon={<Icon as={HiLogout} />}
        aria-label="Logout"
        onClick={logout}
      />
    </HStack>
  );
};
