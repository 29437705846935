import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { DocumentSnapshot, updateDoc } from '@firebase/firestore';
import { useEffect, useRef } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router';
import { useComments } from '../../hooks/useComments';

import { useMedia } from '../../hooks/useMedia';
import { dateFormatter } from '../../lib/dateFormatter';
import { Creative } from '../../types/Creative';
import { Comment as CommentType } from '../../types/Comment';
import { Comment } from '../Comment';
import { CommentCountTag } from '../CommentCountTag';
import { CommentInput } from '../CommentInput';
import { Section } from '../Section';
import { StatusTag } from '../StatusTag';
import { ViewsCountTag } from '../ViewsCountTag';

interface Props {
  creativeSnap?: DocumentSnapshot<Creative>;
}

export const CreativeDetail: React.FC<Props> = ({ creativeSnap }) => {
  const creative = creativeSnap?.data() as Creative;
  const uri = useMedia(creative?.versions?.[0]?.fileRef);

  const [authorSnap] = useDocument(creative?.userRef);
  const author = authorSnap?.data();

  const [comments] = useComments(creativeSnap);

  const history = useHistory();

  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.100', 'gray.600');

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const bumpReadCount = async () => {
      try {
        if (!creativeSnap?.ref) {
          return;
        }

        const prevViews = creativeSnap?.data()?.views || 0;
        const views = prevViews + 1;
        await updateDoc(creativeSnap.ref, { views });
      } catch (error) {
        console.log(error);
      }
    };

    bumpReadCount();
  }, [creativeSnap?.id]);

  const handleClose = () => {
    const id = creative?.campaignRef?.id;
    history.push(`/campaigns/${id}`);
  };

  const scrollToBottom = () => {
    ref.current?.scrollIntoView();
  };

  return (
    <Section
      display="flex"
      flexDirection="column"
      position="relative"
      width="600px"
      height="100vh"
      bgColor={bgColor}
      boxShadow="xl"
    >
      <Stack direction="row" justifyContent="space-between" p={8}>
        <Stack spacing={0}>
          <Text fontSize="md" fontWeight="medium">
            {creative?.title || 'Loading...'}
          </Text>
          <Text fontSize="sm" opacity="0.5">
            {dateFormatter(creative?.createdAt?.toDate())} •{' '}
            {author?.name || 'Loading ...'}
          </Text>
          <HStack pt={4}>
            <CommentCountTag count={comments?.docs?.length} />
            <ViewsCountTag count={creative?.views} />
            <StatusTag status={creative?.status} />
          </HStack>
        </Stack>
        <IconButton
          size="lg"
          aria-label="Close Creative"
          icon={<HiX />}
          onClick={handleClose}
        />
      </Stack>
      <Stack flexDirection="column" flex={1} overflowY="auto">
        <Section
          variant="bottom"
          w="100%"
          p={8}
          pt={2}
          bgColor={bgColor}
          borderBottomColor={borderColor}
        >
          <Image
            src={uri}
            width="full"
            maxHeight="500px"
            borderRadius="lg"
            objectFit="contain"
            align="center"
            boxShadow="2xl"
          />
        </Section>
        <Section
          px={8}
          py={4}
          variant="bottom"
          bgColor={bgColor}
          borderBottomColor={borderColor}
          position="sticky"
          zIndex={9999999}
          top={0}
          left={0}
          right={0}
        >
          <Stack direction="row">
            <Button flex={1} colorScheme="green">
              Approve
            </Button>
            <Button flex={1} colorScheme="red">
              Reject
            </Button>
          </Stack>
        </Section>
        <Section
          display="flex"
          flexDirection="column"
          width="100%"
          bgColor={bgColor}
          borderBottomColor={borderColor}
          flex={1}
          height="100%"
          p={8}
        >
          <Stack direction="column" spacing={8} flex={1}>
            {comments?.docs?.map((node, idx) => (
              <Comment
                key={idx}
                commentSnap={node as DocumentSnapshot<CommentType>}
              />
            ))}
          </Stack>
        </Section>
        <div ref={ref as any} />
      </Stack>
      <Box p={8}>
        <CommentInput creativeSnap={creativeSnap} onSubmit={scrollToBottom} />
      </Box>
    </Section>
  );
};
