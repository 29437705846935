import { VStack } from '@chakra-ui/layout';
import { ChakraComponent } from '@chakra-ui/system';

export const Gradient: ChakraComponent<'div'> = ({ children, ...rest }) => (
  <VStack
    bgImg="linear-gradient( 64.5deg,  rgba(245,116,185,1) 14.7%, rgba(89,97,223,1) 88.7% );"
    justifyContent="center"
    {...rest}
  >
    {children}
  </VStack>
);
