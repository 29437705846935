import { Box } from '@chakra-ui/layout';
import { ChakraComponent } from '@chakra-ui/system';

export const Logo: ChakraComponent<'img', any> = ({
  width = 88,
  height = 21,
  ...rest
}) => (
  <Box width={width} height={height} {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 88 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.671997 13.28C0.671997 17.44 3.712 20.384 8.544 20.384C11.392 20.384 13.504 19.072 14.592 17.888L11.936 14.656C11.264 15.424 10.208 16.032 9.024 16.032C7.168 16.032 5.664 14.912 5.664 12.992C5.664 10.496 7.36 8.51201 9.792 8.51201C11.136 8.51201 12.16 9.18401 12.704 10.08L16.384 7.29601C15.328 5.53601 13.184 4.16001 9.824 4.16001C4.672 4.16001 0.671997 8.19201 0.671997 13.28Z"
        fill="white"
      />
      <path
        d="M14.784 20H19.712L21.824 10.304C22.688 9.40801 24 8.80001 25.376 8.80001C26.048 8.80001 26.72 8.92801 26.976 8.99201L28.032 4.16001C25.696 4.16001 23.968 5.08801 22.688 6.46401L23.136 4.54401H18.208L14.784 20Z"
        fill="white"
      />
      <path
        d="M26.112 13.376C26.112 17.824 29.408 20.384 34.336 20.384C36.224 20.384 38.336 19.904 39.84 18.88L38.336 15.616C37.504 16.224 36 16.576 34.72 16.576C32.16 16.576 31.008 14.784 31.008 14.016V13.824H41.952C42.08 13.28 42.272 12.096 42.272 11.136C42.272 6.62401 39.072 4.16001 35.168 4.16001C29.888 4.16001 26.112 8.41601 26.112 13.376ZM37.888 10.624H31.488C31.744 9.44001 33.088 7.96801 34.944 7.96801C37.088 7.96801 37.92 9.40801 37.92 10.4C37.92 10.464 37.92 10.56 37.888 10.624Z"
        fill="white"
      />
      <path
        d="M55.1632 6.36801C54.1712 4.92801 52.3792 4.16001 50.4592 4.16001C45.0832 4.16001 42.3312 9.50401 42.3312 13.984C42.3312 18.112 44.6672 20.384 48.1232 20.384C49.8512 20.384 51.2272 19.712 52.5392 18.208L52.1552 20H57.0512L60.4752 4.54401H55.5792L55.1632 6.36801ZM47.4192 12.96C47.4192 10.528 49.0512 8.51201 51.3232 8.51201C52.6352 8.51201 53.7552 9.08801 54.3952 9.92001L53.3392 14.592C52.6032 15.456 51.5792 16.032 50.4272 16.032C48.7312 16.032 47.4192 14.816 47.4192 12.96Z"
        fill="white"
      />
      <path
        d="M61.2695 16.736C61.2695 19.232 63.3815 20.384 66.3895 20.384C67.4135 20.384 68.4695 20.16 69.0455 19.712L68.9815 15.776C68.8215 15.904 68.3735 16.032 68.0215 16.032C67.0295 16.032 66.5495 15.52 66.5495 14.752C66.5495 14.592 66.5815 14.304 66.6455 14.048L67.7975 8.80001H70.9335L71.8935 4.54401H68.7575L69.6855 0.320007H64.7575L63.8295 4.54401H61.2695L60.3095 8.80001H62.8695L61.3655 15.616C61.3015 16.032 61.2695 16.544 61.2695 16.736Z"
        fill="white"
      />
      <path
        d="M70.2087 13.28C70.2087 17.28 72.9927 20.384 78.1768 20.384C83.5208 20.384 87.2647 16.064 87.2647 11.296C87.2647 7.26401 84.5128 4.16001 79.2968 4.16001C73.9528 4.16001 70.2087 8.51201 70.2087 13.28ZM82.2408 11.52C82.2408 13.856 80.6728 16.032 78.3688 16.032C76.4488 16.032 75.2647 14.912 75.2647 13.024C75.2647 10.688 76.8007 8.51201 79.1367 8.51201C81.0567 8.51201 82.2408 9.63201 82.2408 11.52Z"
        fill="white"
      />
    </svg>
  </Box>
);
