import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import {
  DocumentSnapshot,
  DocumentReference,
  serverTimestamp,
  addDoc,
  collection,
} from '@firebase/firestore';
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useErrors } from '../../hooks/useErrors';
import { Creative } from '../../types/Creative';
import { Comment } from '../../types/Comment';
import { User } from '../../types/User';
import { firestore } from '../../config/firebase';

interface Props {
  creativeSnap?: DocumentSnapshot<Creative>;
  onSubmit?: () => void;
}

export const CommentInput = ({
  creativeSnap,
  onSubmit,
}: Props): React.ReactElement => {
  const { userRef } = useAuth();
  const { handleError } = useErrors();
  const [value, setValue] = useState<string>('');
  const commentsRef = collection(firestore, 'comments');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value;
    setValue(nextValue);
  };

  const saveComment = async () => {
    try {
      if (!value?.trim()) return;
      if (!creativeSnap?.ref) return;

      const comment = {
        createdAt: serverTimestamp(),
        content: value,
        userRef: userRef as DocumentReference<User>,
        creativeRef: creativeSnap?.ref,
      } as Comment;

      await addDoc(commentsRef, comment);
      setValue('');
      onSubmit?.();
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveComment();
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup size="lg">
        <Input
          size="lg"
          variant="filled"
          placeholder="Write a comment..."
          value={value}
          onChange={handleChange}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={saveComment}>
            Send
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
};
