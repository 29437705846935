export const dateFormatter = (date: Date): string =>
  Intl.DateTimeFormat('en-CA', {
    timeStyle: 'short',
    dateStyle: 'medium',
  }).format(date);

const rtf = new Intl.RelativeTimeFormat('en-CA', {
  localeMatcher: 'best fit',
  numeric: 'auto',
  style: 'short',
});

const msPerMin = 1000 * 60;
const msPerHour = msPerMin * 60;
const msPerDay = msPerHour * 24;
const msPerWeek = msPerDay * 7;
const msPerMonth = msPerWeek * 4;
const msPerYear = msPerMonth * 12;

export const relativeDate = (date: Date, now = new Date()): string | null => {
  if (!date) {
    return null;
  }

  const timeBetween = now.getTime() - date.getTime();
  const withinMin = timeBetween <= msPerMin;
  const withinHour = timeBetween <= msPerHour;
  const withinDay = timeBetween <= msPerDay;
  const withinWeek = timeBetween <= msPerWeek;
  const withinMonth = timeBetween <= msPerMonth;
  const withinYear = timeBetween <= msPerYear;

  const time = (timeRange: number) => Math.floor(timeBetween / timeRange) * -1;

  if (withinMin) {
    return 'now';
  }

  if (withinHour) {
    return rtf.format(time(msPerMin), 'minutes');
  }

  if (withinDay) {
    return rtf.format(time(msPerHour), 'hours');
  }

  if (withinWeek) {
    return rtf.format(time(msPerDay), 'days');
  }

  if (withinMonth) {
    return rtf.format(time(msPerWeek), 'weeks');
  }

  if (withinYear) {
    return rtf.format(time(msPerMonth), 'months');
  }

  return rtf.format(time(msPerYear), 'years');
};
