import { Redirect, Route, RouteProps } from 'react-router';

import { useAuth } from '../../hooks/useAuth';

interface Props extends RouteProps {
  redirectAuthenticatedTo: string;
}

export const UnauthenticatedRoute: React.FC<Props> = ({
  redirectAuthenticatedTo,
  ...props
}) => {
  const { isLoading, user } = useAuth();

  if (isLoading) {
    return null;
  }

  if (user) {
    return <Redirect to={redirectAuthenticatedTo} />;
  }

  return <Route {...props} />;
};
