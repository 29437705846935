import { Redirect, Route } from 'react-router';

import { useAuth } from '../../hooks/useAuth';

export const AuthenticatedRoute: React.FC<any> = (props) => {
  const { isLoading, user } = useAuth();

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
