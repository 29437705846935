import {
  DocumentData,
  DocumentSnapshot,
  query,
  QuerySnapshot,
  where,
  collection,
  orderBy,
} from '@firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { firestore } from '../config/firebase';
import { Creative } from '../types/Creative';

type UseComments = [
  QuerySnapshot<DocumentData> | undefined,
  boolean,
  Error | undefined,
];

export function useComments(
  creativeSnap: DocumentSnapshot<Creative>,
): UseComments {
  const creativeRef = creativeSnap?.ref ?? null;
  const commentsRef = query(
    collection(firestore, 'comments'),
    where('creativeRef', '==', creativeRef),
    orderBy('createdAt', 'desc'),
  ) as any;

  const [commentsSnap, isLoading, error] = useCollection<Comment>(commentsRef);

  if (error) {
    console.log('UseComments.error');
    console.error(error);
  }

  return [commentsSnap, isLoading, error];
}
