import { Redirect, BrowserRouter, Switch } from 'react-router-dom';

import * as screens from '../../screens';
import { AuthenticatedRoute } from '../AuthenticatedRoute';
import { UnauthenticatedRoute } from '../UnauthenticatedRoute';

export const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <UnauthenticatedRoute
        exact
        path="/login"
        redirectAuthenticatedTo="/campaigns/new"
        component={screens.LoginScreen}
      />
      <UnauthenticatedRoute
        exact
        path="/signup"
        redirectAuthenticatedTo="/campaigns/new"
        component={screens.SignUpScreen}
      />
      <AuthenticatedRoute
        exact
        path="/campaigns/:id/creative/:creativeId"
        component={screens.CampaignScreen}
      />
      <AuthenticatedRoute
        exact
        path="/campaigns/:id"
        component={screens.CampaignScreen}
      />
      <Redirect exact path="/" to="/login" />
    </Switch>
  </BrowserRouter>
);
