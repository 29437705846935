import React, { useRef, useState } from 'react';

interface UseUploader {
  uploaderRef: React.MutableRefObject<any>;
  pendingFiles: File[];

  openFileSelect: () => void;
  handleFiles: (target: any) => void;
}

export function useUploader(): UseUploader {
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  const uploaderRef = useRef<any>(null);
  const openFileSelect = () => uploaderRef?.current?.click();

  const handleFiles = ({ target }: any) => {
    const nextFiles = Array.from(target?.files as FileList);
    setPendingFiles([...pendingFiles, ...nextFiles]);
  };

  return {
    uploaderRef,
    pendingFiles,

    openFileSelect,
    handleFiles,
  };
}
