import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { HiChat } from 'react-icons/hi';

interface Props {
  count?: number;
}

export const CommentCountTag = ({ count }: Props): React.ReactElement => (
  <Tag size="md">
    <TagLeftIcon boxSize="16px" as={HiChat} />
    <TagLabel>{count || 0}</TagLabel>
  </Tag>
);
