import { doc, DocumentReference, DocumentSnapshot } from '@firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import { firestore } from '../config/firebase';

import { Account } from '../types/Account';

import { Campaign } from '../types/Campaign';
import { Client } from '../types/Client';
import { useErrors } from './useErrors';

interface CampaignScreenData {
  campaignSnap?: DocumentSnapshot<Campaign>;
  accountSnap?: DocumentSnapshot<Account>;
  clientSnap?: DocumentSnapshot<Client>;
  isLoading: boolean;
}

export function useCampaignScreenData(): CampaignScreenData {
  const { id } = useParams<any>();
  const { handleError } = useErrors();

  const campaignRef = doc(
    firestore,
    'campaigns',
    id,
  ) as DocumentReference<Campaign>;
  const [campaignSnap, campaignIsLoading, campaignError] =
    useDocument<Campaign>(campaignRef);

  const clientRef = campaignSnap?.get('clientRef');
  const [clientSnap, clientIsLoading, clientError] =
    useDocument<Client>(clientRef);

  const accountRef = campaignSnap?.get('accountRef');
  const [accountSnap, accountIsLoading, accountError] =
    useDocument<Account>(accountRef);

  if (campaignError) {
    handleError(campaignError);
  }

  if (clientError) {
    handleError(clientError);
  }

  if (accountError) {
    handleError(accountError);
  }

  const isLoading = campaignIsLoading || clientIsLoading || accountIsLoading;

  return {
    campaignSnap,
    accountSnap,
    clientSnap,

    isLoading,
  };
}
