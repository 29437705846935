import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Heading, HStack, VStack } from '@chakra-ui/layout';
import { ChakraComponent } from '@chakra-ui/system';

import { useEffect, useState } from 'react';
import { HiHashtag } from 'react-icons/hi';
import { Link as RRLink, useParams } from 'react-router-dom';

import { useAuthorizedCampaigns } from '../../hooks/useAuthorizedCampaigns';

export const CampaignMenu: ChakraComponent<'div'> = () => {
  const { id } = useParams<{ id: string }>();
  const [activeId, setActiveId] = useState<string>();

  const [campaignsSnap] = useAuthorizedCampaigns();

  useEffect(() => {
    if (id && id !== 'new') {
      setActiveId(id);
    }
  }, [id]);

  return (
    <VStack width="100%" alignItems="flex-start">
      <HStack pl={3} py={1} width="100%" justifyContent="space-between">
        <Heading variant="menuSection">CAMPAIGNS</Heading>
        <Button size="sm" variant="ghost">
          Add New
        </Button>
      </HStack>
      <VStack width="100%" spacing={1}>
        {campaignsSnap?.docs?.map((snap) => {
          const id = snap?.id;
          const label = snap?.get('name');

          const isActive = activeId === id;

          return (
            <Button
              key={id}
              as={RRLink}
              to={`/campaigns/${id}`}
              width="100%"
              variant={isActive ? 'solid' : 'ghost'}
              size="sm"
              cursor="default"
              justifyContent="flex-start"
              leftIcon={<Icon as={HiHashtag} />}
            >
              {label}
            </Button>
          );
        })}
      </VStack>
    </VStack>
  );
};
