import { VStack, HStack, Button, Heading } from '@chakra-ui/react';
import { Link as RRLink } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import * as React from 'react';

import { Card } from '../../components/Card';
import { Logo } from '../../components/Logo';
import { Page } from '../../components/Page';
import { useAuth } from '../../hooks/useAuth';
import { useErrors } from '../../hooks/useErrors';
import { SimpleInput } from '../../components/SimpleInput';

interface LoginForm {
  email: string;
  password: string;
}

const initialValues: LoginForm = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required').min(8),
});

export const LoginScreen: React.FC = () => {
  const { login } = useAuth();
  const { handleError } = useErrors();

  const onSubmit = async ({ email, password }: LoginForm) => {
    try {
      await login(email, password);
    } catch (reason) {
      handleError(reason);
    }
  };

  const formik = useFormik<LoginForm>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    formik.submitForm();
  };

  return (
    <Page title="Login" withGradient withHeader={false}>
      <VStack minW="500" maxW="100%">
        <FormikProvider value={formik}>
          <Logo mb="6" />
          <Card variant="rounded" width="100%">
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <VStack spacing={8} alignItems="flex-start">
                <Heading>Login</Heading>
                <SimpleInput name="email" label="Email" autoFocus={false} />
                <SimpleInput name="password" label="Password" type="password" />
                <VStack width="100%" spacing={4}>
                  <Button
                    size="lg"
                    colorScheme="cyan"
                    isFullWidth
                    onClick={formik.submitForm}
                  >
                    Login
                  </Button>
                </VStack>
              </VStack>
            </form>
          </Card>
          <HStack p={4} spacing={6} justifyContent="space-between" w="100%">
            <Button as={RRLink} to="/signup" size="lg" flex="1" variant="ghost">
              Need an account?
            </Button>
            <Button as={RRLink} to="/signup" size="lg" flex="1" variant="ghost">
              Forgot password?
            </Button>
          </HStack>
        </FormikProvider>
      </VStack>
    </Page>
  );
};
