import { DocumentData, query, QuerySnapshot, where } from '@firebase/firestore';
import { collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { firestore } from '../config/firebase';
import { useAuth } from './useAuth';

type UseAuthorizedCampaigns = [
  QuerySnapshot<DocumentData> | undefined,
  boolean,
  Error | undefined,
];

export function useAuthorizedCampaigns(): UseAuthorizedCampaigns {
  const { user } = useAuth();
  const uid = user?.uid || null;

  const campaignsRef = query(
    collection(firestore, 'campaigns'),
    where(`roles.${uid}`, 'array-contains-any', ['CREATOR', 'ADMIN']),
  );

  const [campaignsSnap, isLoading, error] = useCollection(campaignsRef);

  if (error) {
    console.log('useAuthorizedCampaigns.error');
    console.error(error);
  }

  return [campaignsSnap, isLoading, error];
}
