import { Box, Heading, Text, VStack } from '@chakra-ui/layout';
import { DocumentSnapshot } from '@firebase/firestore';
import { Account } from '../../types/Account';
import { Campaign } from '../../types/Campaign';
import { CampaignMenu } from '../CampaignMenu';
import { Section } from '../Section';

interface Props {
  campaignSnap?: DocumentSnapshot<Campaign>;
  accountSnap?: DocumentSnapshot<Account>;
}

export const LeftPanel: React.FC<Props> = ({ campaignSnap, accountSnap }) => {
  const campaign = campaignSnap?.data();
  const account = accountSnap?.data();

  return (
    <Section variant="right" height="100vh" width="400px">
      <VStack height="100vh" justifyContent="space-between">
        <Box width="100%" p={4}>
          <CampaignMenu style={{ minWidth: '100%' }} />
        </Box>
        <Section variant="top">
          <VStack spacing={2} p={8} alignItems="flex-start">
            <Text size="sm" color="purple.500">
              {account?.name}
            </Text>
            <Heading size="lg">{campaign?.name}</Heading>
            <Text color="gray.500">{campaign?.description}</Text>
          </VStack>
        </Section>
      </VStack>
    </Section>
  );
};
