import { Input } from '@chakra-ui/input';
import { forwardRef } from 'react';

interface Props {
  onChange: (event: any) => void;
}

export const Uploader = forwardRef(({ onChange }: Props, ref) => (
  <Input ref={ref as any} onChange={onChange} type="file" multiple hidden />
));
