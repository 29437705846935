import { css } from '@emotion/react';

export const styles = {};

export const globalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse, but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
  html,
  body,
  #root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
