import { Flex } from '@chakra-ui/layout';
import { DocumentSnapshot, doc } from '@firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
// import { Sidebar } from '@saas-ui/pro';

import { CreativeDetail } from '../../components/CreativeDetail';
import { CreativeList } from '../../components/CreativeList';
import { LeftPanel } from '../../components/LeftPanel';
import { Page } from '../../components/Page';
import { TopBar } from '../../components/TopBar';
import { Uploader } from '../../components/Uploader';
import { firestore } from '../../config/firebase';
import { useCampaignScreenData } from '../../hooks/useCampaignScreenData';
import { useUploader } from '../../hooks/useUploader';
import { Account } from '../../types/Account';
import { Campaign } from '../../types/Campaign';
import { Creative } from '../../types/Creative';

export interface CampaignProps {
  accountSnap?: DocumentSnapshot<Account>;
  campaignSnap?: DocumentSnapshot<Campaign>;
}

export const CampaignScreen: React.FC = () => {
  const { campaignSnap, accountSnap } = useCampaignScreenData();
  const name = campaignSnap?.get('name');

  const location = useParams<{ creativeId: string }>();
  const { uploaderRef, openFileSelect, handleFiles, pendingFiles } =
    useUploader();

  const creativeRef = location?.creativeId
    ? doc(firestore, `/creative/${location?.creativeId}`)
    : null;
  const [creativeSnap] = useDocument(creativeRef);

  return (
    <Page title={name}>
      <>
        <Flex>
          {/* <Sidebar /> */}
          <LeftPanel accountSnap={accountSnap} campaignSnap={campaignSnap} />
          <Flex
            flex="2"
            flexDirection="column"
            alignSelf="flex-start"
            height="100vh"
          >
            <TopBar
              campaignSnap={campaignSnap}
              onUploadClick={openFileSelect}
            />
            <CreativeList
              campaignSnap={campaignSnap}
              filesToUpload={pendingFiles}
              onUploadClick={openFileSelect}
            />
          </Flex>
          {location?.creativeId && (
            <CreativeDetail
              creativeSnap={creativeSnap as DocumentSnapshot<Creative>}
            />
          )}
        </Flex>
        {campaignSnap && <Uploader onChange={handleFiles} ref={uploaderRef} />}
      </>
    </Page>
  );
};
