import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import 'focus-visible/dist/focus-visible';

import { Global } from '@emotion/react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
// import { SaasProvider } from '@saas-ui/react';

import { globalStyles, theme } from './config';
import { Router } from './components/Router';
import { UtilityFunctions } from './components/UtilityFunctions';

const HelmetProviderTemp = HelmetProvider as any;

ReactDOM.render(
  <React.StrictMode>
    <HelmetProviderTemp>
      <ChakraProvider theme={theme} resetCSS>
        {/* <SaasProvider theme={theme}> */}
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Global styles={globalStyles} />
        <UtilityFunctions />
        <Router />
        {/* </SaasProvider> */}
      </ChakraProvider>
    </HelmetProviderTemp>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
