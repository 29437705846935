import { Card } from './card';
import { Button } from './button';
import { Section } from './section';
import { Heading } from './heading';

export const components = {
  Card,
  Button,
  Section,
  Heading,
};
