import { Box, useStyleConfig } from '@chakra-ui/react';
import { ChakraComponent } from '@chakra-ui/system';

export const Section: ChakraComponent<
  'div',
  {
    variant?: string;
  }
> = ({ variant, children, ...rest }) => {
  const styles = useStyleConfig('Section', { variant });

  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
};
