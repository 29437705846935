import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { HiEye } from 'react-icons/hi';

interface Props {
  count?: number;
}

export const ViewsCountTag = ({ count }: Props): React.ReactElement => (
  <Tag size="md">
    <TagLeftIcon boxSize="16px" as={HiEye} />
    <TagLabel>{count || 0}</TagLabel>
  </Tag>
);
