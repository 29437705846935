import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Center, Text, VStack } from '@chakra-ui/layout';
import { HiCloudUpload } from 'react-icons/hi';

interface Props {
  onUploadClick: () => void;
}

export const AddCreativePrompt: React.FC<Props> = ({ onUploadClick }) => (
  <Center flex="1">
    <VStack spacing={4}>
      <Icon as={HiCloudUpload} fontSize={100} opacity={0.1} />
      <Text>You haven't uploaded any creative.</Text>
      <Button onClick={onUploadClick}>Upload Creative</Button>
    </VStack>
  </Center>
);
