import { Flex, SimpleGrid } from '@chakra-ui/react';
import { DocumentSnapshot } from '@firebase/firestore';

import { useCreativeByCampaign } from '../../hooks/useCreativeByCampaign';
import { Campaign } from '../../types/Campaign';
import { Creative } from '../../types/Creative';
import { AddCreativePrompt } from '../AddCreativePrompt';
import { CreativeItem } from '../CreativeItem';
import { CreativeItemUploader } from '../CreativeItemUploader';
import { Section } from '../Section';

interface Props {
  campaignSnap?: DocumentSnapshot<Campaign>;
  filesToUpload?: File[];
  onUploadClick: () => void;
}

export const CreativeList: React.FC<Props> = ({
  campaignSnap,
  filesToUpload,
  onUploadClick,
}) => {
  const [creativeSnaps] = useCreativeByCampaign({ campaignSnap });
  const hasNoCreative = creativeSnaps?.empty;

  if (hasNoCreative) {
    return (
      <Flex flex={1} height="100%">
        <AddCreativePrompt onUploadClick={onUploadClick} />
      </Flex>
    );
  }

  return (
    <Section flex={1} p={4} height="100%" overflowY="auto">
      {filesToUpload?.map((file, idx) => (
        <CreativeItemUploader
          key={idx}
          campaignSnap={campaignSnap}
          file={file}
        />
      ))}
      <SimpleGrid columns={1}>
        {creativeSnaps?.docs?.map((snap) => (
          <CreativeItem
            key={snap?.id}
            campaignSnap={campaignSnap}
            creativeSnap={snap as DocumentSnapshot<Creative>}
          />
        ))}
      </SimpleGrid>
    </Section>
  );
};
